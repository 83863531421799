import moment from 'moment';
import React, { useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

interface IFiltrosInventarioProps {
    SetRefresh: Function;
    offCanvasFiltrosClose: Function;
}
const FiltrosInventario: React.FC<IFiltrosInventarioProps> = ({ SetRefresh, offCanvasFiltrosClose }) => {
    const { register, formState: { errors }, setValue, getValues, reset } = useForm<any>({
        defaultValues: {
            id: "",
            fechaDesde: "",
            fechaHasta: "",
        }
    });
    async function submitFiltros() {
        try {
            localStorage.setItem('filtrosstock', JSON.stringify(getValues()));
            SetRefresh(moment().format('YYYY-MM-DD HH:mm:ss'));
            offCanvasFiltrosClose();
        } catch (error) {
            console.log(error);
        }
    }
     const resetearFiltros = (e: React.MouseEvent<HTMLButtonElement>, origen: string) => {
        e.preventDefault();
        reset({
            fechaDesde: "",
            fechaHasta: "",
        });
        localStorage.removeItem('filtrosstock');
        SetRefresh(moment().format('YYYY-MM-DD HH:mm:ss'));
        offCanvasFiltrosClose();
        
      };
    useEffect(() => {
        const filtros = localStorage.getItem('filtrosstock');
        if (filtros) {
            const { fechaDesde, fechaHasta } = JSON.parse(filtros);
            setValue("fechaDesde", fechaDesde);
            setValue("fechaHasta", fechaHasta);
        }
    }
    , []);
    return (
        <>
            <div className='row'>
                <div className='col-12'>
                    <div className='form-group'>
                        <Form.Label>Fecha desde</Form.Label>
                        <Form.Control type="date" {...register("fechaDesde", { required: true })} />
                        {errors.fechaDesde && <span className="text-danger">Campo requerido</span>}
                    </div>
                    <div>
                        <Form.Label>Fecha hasta</Form.Label>
                        <Form.Control type="date" {...register("fechaHasta", { required: true })} />
                        {errors.fechaHasta && <span className="text-danger">Campo requerido</span>}
                    </div>
                </div>
                <div className='form-group mt-4'>
                    <button onClick={(e) => {
                        resetearFiltros(e, 'articulos')
                    }} className='btn btn-warning w-100 btn-sm'>
                        Resetear
                    </button>
                </div>
                <div className='form-group mt-2'>
                    <button onClick={submitFiltros} className='btn btn-primary w-100 btn-sm'>
                        Buscar
                    </button>
                </div>
            </div>
        </>
    )
}

export default FiltrosInventario;