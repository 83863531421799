import { useEffect, useState } from 'react';
import { Form, FormGroup, Image, Row, Col, Tabs, Tab } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { GetEureka } from '../estructura/EurekaGeneral/EurekaGeneral';
import Tabla from '../estructura/EurekaTabla/EurekaTabla';
import ups from '../../images/ups.png';
import pideuva from '../../images/pideuva.png';
import pedidosya from '../../images/pedidosya.png';
import oca from '../../images/oca.png';
import urbano from '../../images/urbano.png';
import andreani from '../../images/andreani.jpg';
import EurekaOverlayTrigger from '../estructura/EurekaOverlayTrigger/EurekaOverlayTrigger';
import notiflix from 'notiflix';
import Select from 'react-select';
import Notiflix, { Loading } from 'notiflix';
import axios from 'axios';
const { REACT_APP_URL_BLUEHOST, REACT_APP_URL_MS_ANDREANI } = process.env;
interface IFormCotizarEntrega {
  [key: string | number]: any;
  entregas?: { id: number; nroventa: string }[];
  setEntregas?: Function;
  mensajeStep3: string;
  mensajeStep4: string;
  setIdProveedor: Function;
  idProveedor: any;
  loading: boolean;
  setValorCotizacion: Function;
  valorCotizacion: {
    [key: string | number]: any;
    codigoSeguimiento: string;
    descripcion: string;
    idproveedor: number;
    resultado: string;
  };
  datos?: {
    show: boolean;
    tipo: number;
    accion: string;
  };
  formCotizacion: IFormCotizacion[];
  setFormCotizacion: Function;
  identrega: any[];
  formDisabled?: boolean;
  datosCotizacionFinal: any[];
  cpManual: { origen: boolean; destino: boolean };
  setcpManual?: Function;
  tipo?: string;
  crearCuerpo?: Function;
  disabledLocalidad?: any;
  setdisabledLocalidad?: Function;
  valuesSelect?: any;
  setvaluesSelect?: Function;
  combosProvincias?: any[];
  combosLocalidadesOrigen?: any[];
  combosLocalidadesDestino?: any[];
  setcombosLocalidadesOrigen?: Function;
  setcombosLocalidadesDestino?: Function;
  arrayProductos?: IProducto[];
  setArrayProductos?: Function;
  producto?: IProducto;
  setProducto?: Function;
  checkCotizacion?: boolean;
  setCheckCotizacion?: Function;
  isCotizacion?: boolean;
  isManual?: boolean;
}

interface IProducto {
  valor: string;
  cantidad: number;
  alto: number;
  ancho: number;
  largo: number;
  peso: number;
  articulo: string;
}

interface IFormCotizacion {
  [key: string | number]: any;
  idpais: number;
  cuit?: string;
  idproveedor: number[];
  identrega: number;
  nro_venta: string;
  origen: {
    pais: string;
    codigoPostal: number;
    provincia: string;
    ciudad: string;
    direccion: string;
    latitud: number;
    longitud: number;
    telefono: string;
    nombreEnvia: string;
    observaciones: string;
  };
  destino: {
    pais: string;
    codigoPostal: number;
    provincia: string;
    ciudad: string;
    direccion: string;
    latitud: number;
    longitud: number;
    telefono: string;
    nombreEnvia: string;
    observaciones: string;
  };
  productos: { articulo: string; cantidad: number; largo: number; ancho: number; alto: number; peso: number }[];
  idtipoconector?: number;
  proveedores?: {
    rapiboy: boolean;
    pedidosya: boolean;
    rappi: boolean;
    urbano: boolean;
  };
  entregas?: { id: number; nroventa: string }[];
}
const EurekaStepCotizacion: React.FC<IFormCotizarEntrega> = ({ mensajeStep3, identrega = [], formDisabled = false, tipo, cpManual, setcpManual, crearCuerpo, disabledLocalidad, setdisabledLocalidad, valuesSelect, setvaluesSelect, combosProvincias = [], combosLocalidadesOrigen = [], combosLocalidadesDestino = [], setcombosLocalidadesOrigen, setcombosLocalidadesDestino, formCotizacion, setFormCotizacion, valorCotizacion, setValorCotizacion, setIdProveedor, idProveedor, arrayProductos = [], setArrayProductos, producto, setProducto, mensajeStep4, loading, checkCotizacion, setCheckCotizacion, datosCotizacionFinal, isCotizacion = false, entregas = [], setEntregas = () => { }, isManual = false }) => {
  const [habilitar, setHabilitar] = useState({
    segundo: true,
    cuarto: true,
  });
  function valueIdProveedor(name: string) {
    switch (name) {
      case 'peya':
        return 1;
      case 'urb':
        return 6;
      case 'oca':
        return 4;
      case 'andreani':
        return 7;
      default:
        return 0;
    }
  }
  function cambiarValueChecks(e: React.ChangeEvent<HTMLInputElement>) {
    let valor = parseInt(e.target.value);
    if (e.target.checked) {
      if (e.target.name === 'idproveedor') {
        let newFormCotizacion = formCotizacion.map((value, k) => {
          return { ...value, idproveedor: [...formCotizacion[k].idproveedor, valor] };
        });
        setFormCotizacion(newFormCotizacion);
      } else {
        let newFormCotizacion = formCotizacion.map((value, k) => {
          return { ...value, idpais: valor };
        });
        setFormCotizacion(newFormCotizacion);
      }
    } else {
      if (e.target.name === 'idproveedor') {
        let newFormCotizacion = formCotizacion.map((value, k) => {
          return { ...value, idproveedor: formCotizacion[k].idproveedor.filter((val) => val != valor) };
        });
        setFormCotizacion(newFormCotizacion);
      } else {
        let newFormCotizacion = formCotizacion.map((value, k) => {
          return { ...value, idpais: 0 };
        });
        setFormCotizacion(newFormCotizacion);
      }
    }
  }
  function changeIdProveedor(e: React.ChangeEvent<HTMLInputElement>, id: number, key: number, name: string) {
    if (e.target.checked) {
      let newObject: any = {};
      if (Object.values(idProveedor)?.length) {
        for (let llave in idProveedor) {
          if (llave.includes(`${key}`) && llave !== `${key}${name}`) delete idProveedor[llave];
          else newObject[key + name] = { ...idProveedor, [key + name]: { id: id, nro_venta: e.target.name, idproveedor: valueIdProveedor(name), key } };
        }
        setIdProveedor({ ...idProveedor, [key + name]: { id: id, nro_venta: e.target.name, idproveedor: valueIdProveedor(name), key } });
      } else {
        setIdProveedor({ ...idProveedor, [key + name]: { id: id, nro_venta: e.target.name, idproveedor: valueIdProveedor(name), key } });
      }
    } else {
      let newObject = { ...idProveedor };
      for (let llave in newObject) {
        if (llave.includes(`${key}`)) {
          delete newObject[key + name];
          break;
        } else {
          newObject[key + name] = newObject[llave];
        }
      }
      setIdProveedor(newObject);
    }
  }
  function masajeoProductos(productos: any) {
    return Array.isArray(productos) ? productos?.map((value) => ({ nombre: value.articulo, cantidad: value.cantidad, alto: value?.alto, ancho: value?.ancho, largo: value?.largo, peso: value?.peso, valor: value?.valor })) : [{ nombre: '', cantidad: 0, alto: 0, ancho: 0, largo: 0, peso: 0, valor: 0 }];
  }
  function devolverChecksProveedores(idpais: number) {
    switch (idpais) {
      case 21:
        return (
          <Row className='d-flex justify-content-center'>
            <Col className='d-flex justify-content-between aling-items-center border col-12 col-lg-6'>
              <Form.Check key='0' onChange={cambiarValueChecks} defaultValue='3' name='idproveedor' checked={formCotizacion[0]?.idproveedor.includes(3)} />
              <Image className='image-conectores' src={ups} fluid alt='UPS' />
            </Col>
            <Col className='d-flex justify-content-between aling-items-center border col-12 col-lg-6'>
              <Form.Check key='0' onChange={cambiarValueChecks} defaultValue='2' name='idproveedor' checked={formCotizacion[0]?.idproveedor.includes(2)} />
              <Image className='image-conectores' src={pideuva} fluid alt='Pideuva' />
            </Col>
          </Row>
        );
      case 1:
        return (
          <Row>
            <Col className='d-flex justify-content-between aling-items-center border col-12 col-lg-6'>
              <Form.Check key='0' className='col-1' onChange={cambiarValueChecks} defaultValue='1' name='idproveedor' checked={formCotizacion[0]?.idproveedor.includes(1)} />
              <div className='col-10'>
                <Image src={pedidosya} className='image-conectores' alt='pedidosya' />
              </div>
            </Col>
            <Col className='d-flex justify-content-start aling-items-center border col-12 col-lg-6'>
              <Form.Check key='0' className='col-1' onChange={cambiarValueChecks} defaultValue='6' name='idproveedor' checked={formCotizacion[0]?.idproveedor.includes(6)} />
              <div className='col-10'>
                <Image src={urbano} className='image-conectores' alt='urbano' />
              </div>
            </Col>
            <Col className='d-flex justify-content-start aling-items-center border col-12 col-lg-6'>
              <Form.Check key='0' className='col-1' onChange={cambiarValueChecks} defaultValue='4' name='idproveedor' checked={formCotizacion[0]?.idproveedor.includes(4)} />
              <div className='col-10'>
                <Image src={oca} className='image-conectores' alt='oca' />
              </div>
            </Col>
            <Col className='d-flex justify-content-start aling-items-center border col-12 col-lg-6'>
              <Form.Check key='0' className='col-1' onChange={cambiarValueChecks} defaultValue='7' name='idproveedor' checked={formCotizacion[0]?.idproveedor.includes(7)} />
              <div className='col-10'>
                <Image src={andreani} className='image-conectores' alt='andreani' />
              </div>
            </Col>
          </Row>
        );
      default:
        return (
          <Row>
            <Col className='d-flex justify-content-center aling-items-center border col-4'>
              <Form.Check key='0' onChange={cambiarValueChecks} defaultValue='3' name='idproveedor' checked={formCotizacion[0]?.idproveedor.includes(3)} />
              <Image src={ups} fluid />
            </Col>
            <Col className='d-flex justify-content-center aling-items-center border col-4'>
              <Form.Check key='0' onChange={cambiarValueChecks} defaultValue='2' name='idproveedor' checked={formCotizacion[0]?.idproveedor.includes(2)} />
              <Image src={pideuva} fluid />
            </Col>
          </Row>
        );
    }
  }
  const cambiarValue: any = (e: any, key: any) => {
    if (e.value && e.label) {
      let valores = e.value.split('?');
      let valor = valores[0] + '?' + valores[1];
      tipoDeSeteo(valores[2], valor, false, e.select, null);
      if (valores[2] === 'direccion_origen') {
        if (key.name == 'provincia_origen') {
          setFormCotizacion((prevState: any[]) => {
            return prevState.map((item: { origen: any; }, index: any) => {
              return {
                ...item,
                origen: {
                  ...item.origen,
                  provincia: valores[0]
                },
              };
            });
          });
        } else if (key.name == 'ciudad_origen') {
          setFormCotizacion((prevState: any[]) => {
            return prevState.map((item: { origen: any; }, index: any) => {
              return {
                ...item,
                origen: {
                  ...item.origen,
                  ciudad: valores[0]
                },
              };
            });
          });
        }
      }
      else {
        if (key.name == 'provincia_destino') {
          setFormCotizacion((prevState: any[]) => {
            return prevState.map((item: { destino: any; }, index: any) => {
              return {
                ...item,
                destino: {
                  ...item.destino,
                  provincia: valores[0]
                },
              };
            });
          });
        } else if (key.name == 'ciudad_destino') {
          setFormCotizacion((prevState: any[]) => {
            return prevState.map((item: { destino: any; }, index: any) => {
              return {
                ...item,
                destino: {
                  ...item.destino,
                  ciudad: valores[0]
                },
              };
            });
          });
        }
      }
    } else {
      tipoDeSeteo(e.target.name, e.target.value, e.target.checked, '', key);
    }
  };
  function eliminarProducto(id: string, key: number) {
    const productoEliminado = arrayProductos?.filter((value: any) => value.articulo !== id);
    const newFormCotizacion = formCotizacion.map((value, k) => (k === key ? { ...value, productos: productoEliminado } : value));
    setArrayProductos && setArrayProductos(productoEliminado);
    setFormCotizacion(newFormCotizacion);
  }
  function agregarProducto(e: React.MouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    if (arrayProductos?.find((value) => value.articulo === producto?.articulo)) {
      notiflix.Report.failure('Error', 'Ya creo un producto con este nombre', 'Continuar');
    } else {
      if (producto) {
        if (parseInt(producto.valor) > 0 && producto.cantidad > 0 && producto.alto > 0 && producto.ancho > 0 && producto.largo > 0 && producto.peso > 0 && producto?.articulo?.length) {
          setArrayProductos && setArrayProductos([...arrayProductos, producto]);
          setFormCotizacion([
            {
              ...formCotizacion[0],
              productos: [...arrayProductos, producto],
            },
          ]);
        } else notiflix.Report.failure('Error', 'Falto completar algun campo para la creación del producto.', 'Continuar');
      } else notiflix.Report.failure('Error', 'El producto es undefined', 'Continuar');
    }
  }
  function cambiarProducto(e: React.ChangeEvent<HTMLInputElement>) {
    setProducto &&
      setProducto({
        ...producto,
        [e.target.name]: e.target.name === 'articulo' ? e.target.value : Number(e.target.value),
      });
  }
  function devolverSeteoDireccion(formCotizacion: any, nombre: string, values: any, select: string) {
    if (select === 'primer' || select === 'tercero') {
      let array = formCotizacion[nombre]?.split(',');
      let conComa = values[0]?.length ? ',' + values[0] : '';
      return `${array[0]}${conComa},${array[2]}`;
    } else {
      let array = formCotizacion[nombre]?.split(',');
      let conComa = values[0]?.length ? ',' + values[0] : '';
      return `${array[0]},${array[1]}${conComa}`;
    }
  }
  function tipoDeSeteo(nombre: string, valor: string, chequeo: boolean, select: string, key: number | null) {
    if (nombre === 'idproveedor') {
      let arraySetear = formCotizacion?.map((value, k) => {
        value[nombre] = chequeo ? [...value[nombre], Number(valor)] : value[nombre].filter((valor) => valor !== Number(valor));
        return value;
      });
      setFormCotizacion(arraySetear);
    } else if (nombre === 'idpais') {
      if (chequeo === false) {
        let arraySetear = formCotizacion.map((value, k) => {
          value[nombre] = 0;
          value.idproveedor = [];
          return value;
        });
        setFormCotizacion(arraySetear);
      } else {
        let arraySetear = formCotizacion.map((value, k) => {
          value[nombre] = Number(valor);
          return value;
        });
        setFormCotizacion(arraySetear);
      }
    } else if (nombre === 'direccion_origen') {
      let values = valor.split('?');
      if (!values.includes('0')) {
        setFormCotizacion([
          {
            ...formCotizacion[0],
            [nombre]: devolverSeteoDireccion(formCotizacion[0], nombre, values, select),
          },
        ]);
        setvaluesSelect && valuesSelect && setvaluesSelect({ ...valuesSelect, [select]: { value: `${values[0]}?${nombre}?direccion_origen`, label: values[0], select } });
        if (select === 'primer') {
          GetEureka('/coordenadas/combos/localidades/' + values[1]).then((value) => {
            setcombosLocalidadesOrigen && setcombosLocalidadesOrigen(value.content);
            setdisabledLocalidad &&
              setdisabledLocalidad({
                ...disabledLocalidad,
                [nombre]: false,
              });
            return true;
          });
        } else if (select === 'segundo')
          setHabilitar({
            ...habilitar,
            [select]: false,
          });
      } else {
        setvaluesSelect && setvaluesSelect({ ...valuesSelect, [select]: { value: 0, label: values[0] }, segundo: { label: 'Seleccione', value: 0 } });
        setFormCotizacion({
          ...formCotizacion[0],
          [nombre]: devolverSeteoDireccion(formCotizacion, nombre, ['', ''], select),
        });
        if (select === 'segundo' || select === 'primer')
          setHabilitar({
            ...habilitar,
            segundo: true,
          });
      }
    } else if (nombre === 'direccion_destino') {
      let values = valor.split('?');
      if (!values.includes('0')) {
        setFormCotizacion([
          {
            ...formCotizacion[0],
            [nombre]: devolverSeteoDireccion(formCotizacion[0], nombre, values, select),
          },
        ]);
        setvaluesSelect && setvaluesSelect({ ...valuesSelect, [select]: { value: `${values[0]}?${nombre}?direccion_destino`, label: values[0], select } });
        if (select === 'tercero') {
          GetEureka('/coordenadas/combos/localidades/' + values[1]).then((value) => {
            setcombosLocalidadesDestino && setcombosLocalidadesDestino(value.content);
            setdisabledLocalidad &&
              setdisabledLocalidad({
                ...disabledLocalidad,
                [nombre]: false,
              });
            return true;
          });
        } else if (select === 'cuarto')
          setHabilitar({
            ...habilitar,
            [select]: false,
          });
      } else {
        setvaluesSelect && setvaluesSelect({ ...valuesSelect, [select]: { value: 0, label: values[0] }, cuarto: { label: 'Seleccione', value: 0 } });
        setFormCotizacion({
          ...formCotizacion[0],
          [nombre]: devolverSeteoDireccion(formCotizacion, nombre, ['', ''], select),
        });
        if (select === 'cuarto' || select === 'tercero')
          setHabilitar({
            ...habilitar,
            cuarto: true,
          });
      }
    } else {
      let nombresArray: string[] = nombre?.split('-');
      let obj: any = formCotizacion[0][nombresArray[1]];
      setFormCotizacion([
        {
          ...formCotizacion[0],
          [nombresArray[1]]: {
            ...obj,
            [nombresArray[0]]: valor,
          },
        },
      ]);
    }
  }
  function devolverOrigenTienda(idproveedor: number[], formCoti: any) {
    if (idproveedor.includes(3) || idproveedor.includes(2) || idproveedor.includes(5)) {
      return (
        <FormGroup className='col-12 pt-0 m-0'>
          <Form.Label className='gilroy-bold'>Nombre de la tienda</Form.Label>
          <Form.Control disabled={formDisabled} onChange={cambiarValue} name='nombreEnvia-origen' value={formCoti?.origen?.nombreEnvia} className='colorLetra raleway-regular'></Form.Control>
        </FormGroup>
      );
    } else {
      return (
        <FormGroup className='col-12 pt-0 m-0'>
          <Form.Label className='gilroy-bold'>Envia</Form.Label>
          <Form.Control disabled={formDisabled} onChange={cambiarValue} name='envia-origen' value={formCoti?.origen?.envia} className='colorLetra raleway-regular'></Form.Control>
        </FormGroup>
      );
    }
  }
  function devolverDestinoTienda(idproveedor: number[], formCoti: any) {
    if (idproveedor.includes(3) || idproveedor.includes(2) || idproveedor.includes(5)) {
      return (
        <FormGroup className='col-12 pt-0 m-0'>
          <Form.Label className='gilroy-bold'>Nombre de la tienda</Form.Label>
          <Form.Control disabled={formDisabled} onChange={cambiarValue} name='nombreEnvia-destino' value={formCoti?.destino?.nombreEnvia} className='colorLetra raleway-regular'></Form.Control>
        </FormGroup>
      );
    } else {
      return (
        <FormGroup className='col-12 pt-0 m-0'>
          <Form.Label className='gilroy-bold'>Recibe</Form.Label>
          <Form.Control disabled={formDisabled} onChange={cambiarValue} name='recibe-destino' value={formCoti?.destino?.recibe} className='colorLetra raleway-regular'></Form.Control>
        </FormGroup>
      );
    }
  }
  function devolverHeadTable(idproveedor: number[]) {
    return (
      <th>
        {idproveedor.sort()?.map((value, key) => {
          switch (value) {
            case 1:
              return <Image key={key} src={pedidosya} className='image-conectores' alt='pedidosya' />;
            case 2:
              return <Image key={key} src={pideuva} className='image-conectores' alt='pideuva' />;
            case 3:
              return <Image key={key} src={ups} className='image-conectores' alt='ups' />;
            case 6:
              return <Image key={key} src={urbano} className='image-conectores' alt='urbano' />;
            case 4:
              return <Image key={key} src={oca} className='image-conectores' alt='oca' />;
            case 7:
              return <Image key={key} src={andreani} className='image-conectores' alt='andreani' />;
            default:
              return '';
          }
        })}
      </th>
    );
  }
  function devolverPeyaTable(value: IDevolverTablaEnvios, accessKey: number) {
    return (
      <div className='d-flex justify-content-center'>
        {value?.info ? (
          <div className='d-flex align-items-center'>
            <div className='pe-2'>
              <Form.Check disabled={!value.info} onChange={(e) => changeIdProveedor(e, value?.idproveedor, accessKey, devolverName(value?.idproveedor))} value={value?.idproveedor} name={value?.nro_venta} checked={idProveedor[accessKey + devolverName(value?.idproveedor)]?.idproveedor === value.idproveedor} />
            </div>
            <p className='raleway-bold'>
              {value?.costo} - {value?.moneda}
            </p>
          </div>
        ) : (
          <div className='d-flex justify-content-center'>
            <EurekaOverlayTrigger isButton={true} sizeButton='sm' variant='danger' title='Error' style={{ display: 'block' }}>
              {value?.msg}
            </EurekaOverlayTrigger>
          </div>
        )}
      </div>
    );
  }
  function devolverName(idproveedor: number) {
    switch (idproveedor) {
      case 1:
        return 'peya';
      case 6:
        return 'urb';
      case 4:
        return 'oca';
      case 7:
        return 'andreani';
      default:
        return '';
    }
  }
  function devolverUrbanoTable(value: IDevolverTablaEnvios, accessKey: number) {
    return (
      <div className='d-flex justify-content-center'>
        {value?.info ? (
          <div key={accessKey} className='d-flex align-items-center'>
            <div className='pe-2'>
              <Form.Check disabled={!value.info} onChange={(e) => changeIdProveedor(e, value?.idproveedor, accessKey, devolverName(value?.idproveedor))} value={value?.idproveedor} name={value?.nro_venta} checked={idProveedor[accessKey + devolverName(value?.idproveedor)]?.idproveedor === value.idproveedor} />
            </div>
            <p className='raleway-bold'>
              {value?.costo} - {value?.moneda}
            </p>
          </div>
        ) : (
          <div className='d-flex justify-content-center'>
            <EurekaOverlayTrigger isButton={true} sizeButton='sm' variant='danger' title='Error' style={{ display: 'block' }}>
              {value?.costo}
            </EurekaOverlayTrigger>
          </div>
        )}
      </div>
    );
  }
  function devolverOcaTable(value: IDevolverTablaEnvios, accessKey: number) {
    return (
      <div className='d-flex justify-content-center'>
        {value?.info ? (
          <div key={accessKey} className='d-flex align-items-center'>
            <div className='pe-2'>
              <Form.Check disabled={!value.info} onChange={(e) => changeIdProveedor(e, value?.idproveedor, accessKey, devolverName(value?.idproveedor))} value={value?.idproveedor} name={value?.nro_venta} checked={idProveedor[accessKey + devolverName(value?.idproveedor)]?.idproveedor === value.idproveedor} />
            </div>
            <p className='raleway-bold'>
              {value?.costo} - {value?.moneda}
            </p>
          </div>
        ) : (
          <div className='d-flex justify-content-center'>
            <EurekaOverlayTrigger isButton={true} sizeButton='sm' variant='danger' title='Error' style={{ display: 'block' }}>
              {value?.costo}
            </EurekaOverlayTrigger>
          </div>
        )}
      </div>
    );
  }
  function devolverValor(isManual: boolean, value: IDevolverTablaEnvios) {
    if (value.nro_venta) return value.nro_venta;
    if (value.codigoSeguimiento) return value.codigoSeguimiento;
  }

  interface IDevolverTablaEnvios {
    [key: string | number]: string | number | boolean;
    idproveedor: number;
    identrega: number;
    idtipoconector: number;
    codigoSeguimiento: string;
    nro_venta: string;
    msg: string;
    info: boolean;
    costo: number;
    moneda: string;
  }
  function devolverTablaEnvios(datos: IDevolverTablaEnvios[]) {
    return datos?.map((value, key: number) => (
      <div key={key} className='d-flex align-items-center w-auto border rounded p-2 mt-2'>
        {devolverHeadTable([value?.idproveedor])}
        <div>
          {value.info ? (
            <div className='ms-3'>
              <EurekaOverlayTrigger isButton={false} title={<a className='text-decoration-underline fs-6 text-tabla-envios' onClick={() => imprimirEntrega(value.identrega, value.idtipoconector, value)}>{`#${devolverValor(isManual, value)}`}</a>} style={{ display: 'block', marginTop: '2px' }}>
                Toque para imprimir
              </EurekaOverlayTrigger>
              <h6 className='f-6 text-tabla-envios'>{`Cod seguimiento: ${value?.codigoSeguimiento}`}</h6>
              <a href={REACT_APP_URL_BLUEHOST + 'seguimiento/' + value?.nro_venta} className='f-6 text-tabla-envios text-decoration-underline' target='_blank' rel='noreferrer'>
                Seguimiento
              </a>
            </div>
          ) : (
            <div className='ms-3'>
              <h6 className='text-tabla-envios'>{`#${value.nro_venta}`}</h6>
              <EurekaOverlayTrigger isButton={true} sizeButton='sm' variant='danger' title='Error' style={{ display: 'block' }}>
                {value.msg}
              </EurekaOverlayTrigger>
            </div>
          )}
        </div>
      </div>
    ));
  }
  function eliminarEntrega(identrega: number) {
    setEntregas(entregas.filter((value) => value.id !== identrega));
  }
  function devolverTr(val: any, k: any, arrayMayor: any) {
    if (arrayMayor.length === 1) {
      let buscando = arrayMayor.find((value: any, key: any) => value.idproveedor === 1);
      if (buscando) {
        return (
          <tr key={k}>
            <td key={k}>{val?.nro_venta}</td>
            <td>{devolverPeyaTable(val, k)}</td>
          </tr>
        );
      } else {
        return (
          <tr key={k}>
            <td>{val?.nro_venta}</td>
            <td>{devolverUrbanoTable(val, k)}</td>
          </tr>
        );
      }
    } else if (arrayMayor.length === 2) {
      return (
        <tr key={k}>
          <td>{val?.nro_venta}</td>
          <td>{devolverPeyaTable(val, k)}</td>
          <td>{devolverUrbanoTable(Object.values(valorCotizacion || [])[1][k], k)}</td>
        </tr>
      );
    } else if (arrayMayor.length > 1) {
      return (
        <tr key={k}>
          <td key={k}>{val?.nro_venta}</td>
          <td>{devolverPeyaTable(val, k)}</td>
          <td>{devolverUrbanoTable(Object.values(valorCotizacion || [])[1][k], k)}</td>
          <td>{devolverOcaTable(Object.values(valorCotizacion || [])[2][k], k)}</td>
        </tr>
      );
    }
  }
  function devolverTrHead(obj: any) {
    if (formCotizacion[0].idpais === 1) {
      return (
        <tr>
          <th>Entrega</th>
          {formCotizacion[0].idproveedor.sort().map((value, key) => {
            switch (value) {
              case 1:
                return (
                  <th key={key}>
                    <Image src={pedidosya} className='image-conectores img-fluid' alt='pedidosya' />
                  </th>
                );
              case 6:
                return (
                  <th key={key}>
                    <Image src={urbano} className='image-conectores img-fluid' alt='urbano' />
                  </th>
                );
              case 4:
                return (
                  <th key={key}>
                    <Image src={oca} className='image-conectores img-fluid' alt='oca' />
                  </th>
                );
              case 7:
                return (
                  <th key={key}>
                    <Image src={andreani} className='image-conectores img-fluid' alt='andreani' />
                  </th>
                );
              default:
                return '';
            }
          })}
        </tr>
      );
    } else {
      return (
        <tr>
          <th>Entrega</th>
          <th>
            <Image src={pideuva} className='image-conectores' alt='pideuva' />
          </th>
          <th>
            <Image src={ups} className='image-conectores' alt='ups' />
          </th>
        </tr>
      );
    }
  }
  function imprimirEntrega(identrega: number, idtipoconector: number, value: any) {
    Loading.circle();

    if (value.idproveedor === 7) {
      const token = localStorage.getItem("token");
       
      axios
        .get(`${REACT_APP_URL_MS_ANDREANI}/impresion?token=${token}&urlEtiqueta=${value.etiqueta}`, {
          responseType: "blob",
        })
        .then((response: any) => {
          const blob = new Blob([response.data], { type: "application/pdf" });
          const url = URL.createObjectURL(blob);
          window.open(url);
          Loading.remove();
        })
        .catch((error) => {
          console.error("Error al obtener el PDF", error);
          Loading.remove();
        });
    } else {
      GetEureka('/impresiones/imprimir/imprimir_entregas_by_id', `&identrega=${identrega}&idtipoconector=${idtipoconector}`)
        .then((response) => {
          Loading.remove();
          window.open(response.content);
        })
        .catch((error) => {
          Loading.remove();
          console.error('Error:', error);
        });
    }
  }
  useEffect(() => {
    setValorCotizacion({});
    if (!habilitar.segundo && !habilitar.cuarto) {
      Notiflix.Report.warning('Busqueda de datos', 'Nos encargaremos de buscar los codigos postales de las direcciones dadas, en caso de no encontrarlas se le dara la opción de ingresar manualmente los datos.', 'Avanzar', async () => {
        let cuerpo = crearCuerpo && (await crearCuerpo(formCotizacion[0]));
        setHabilitar({
          segundo: true,
          cuarto: true,
        });
        if (!String(cuerpo?.origen?.codigoPostal).length || !String(cuerpo?.destino?.codigoPostal).length) {
          setcpManual &&
            setcpManual({
              origen: true,
              destino: true,
            });
        } else {
          Notiflix.Notify.success('Codigos postales encontrados con exito.', {
            position: 'right-bottom',
          });
        }
      });
    }
    setEntregas(identrega);
  }, [habilitar]);
  return (
    <>
      <div id='step-1' className='tab-pane' role='tabpanel' aria-labelledby='step-1' style={{ display: 'block' }}>
        <h2 className='gilroy-medium'>Seleccione el proveedor.</h2>
        <div className='row mt-0'>
          <Col className='col-12 col-lg-2 d-flex flex-column justify-content-start align-items-center'>
            {Array.isArray(entregas) &&
              entregas?.map((value, key) => (
                <span key={key} className='badge bg-primary p-0 pe-2 mt-1'>
                  <button className='btn btn-primary btn-sm'>
                    <FontAwesomeIcon icon={faXmark} onClick={() => eliminarEntrega(value.id)} />
                  </button>
                  {value.nroventa}
                </span>
              ))}
          </Col>
          <Form.Group className='col-12 col-lg-3 d-flex flex-column justify-content-start align-items-center'>
            <Form.Label className='gilroy-regular'>País</Form.Label>
            <Form.Group>
              <Form.Check className='raleway-medium' onChange={cambiarValue} defaultValue='1' label='Argentina' name='idpais' checked={formCotizacion[0]?.idpais === 1} />
              <Form.Check className='raleway-medium' onChange={cambiarValue} defaultValue='21' label='Puerto rico' name='idpais' checked={formCotizacion[0]?.idpais === 21} />
            </Form.Group>
          </Form.Group>
          <div className='col-12 col-lg-7' hidden={formCotizacion[0]?.idpais === 0}>
            <Form.Group className='d-flex flex-column justify-content-start align-items-center'>
              <Form.Label className='gilroy-regular'>Proveedor</Form.Label>
              <div className='d-flex'>{devolverChecksProveedores(formCotizacion[0]?.idpais)}</div>
            </Form.Group>
          </div>
        </div>
      </div>
      <div id='step-2' className='tab-pane' role='tabpanel' aria-labelledby='step-2' style={{ display: 'block' }}>
        <h2 className='gilroy-medium'>Ingrese el origen y destino del envio.</h2>
        {isCotizacion ? (
          <>
            {formCotizacion?.map((formCoti, key) => {
              return (
                <div className='row mt-2 border-top' key={key}>
                  <div className='row m-0 p-0 justify-content-between align-items-start'>
                    <div className='col-12 col-lg-4 row mt-2 border rounded'>
                      <Form.Label className='fs-5 gilroy-bold'>Origen</Form.Label>
                      <FormGroup className='col-12 pt-0 m-0'>
                        <Form.Label className='gilroy-bold'>Dirección</Form.Label>
                        <Form.Control disabled={formDisabled} onChange={cambiarValue} name='direccion-origen' value={formCoti.origen.direccion} className='colorLetra raleway-regular'></Form.Control>
                      </FormGroup>
                      <FormGroup className='col-12 pt-0 m-0'>
                        <Form.Label className='gilroy-bold'>Provincia</Form.Label>
                        {tipo === 'ct' ? <Form.Control disabled={formDisabled} onChange={cambiarValue} name='provincia_origen' value={formCoti.origen.provincia} className='colorLetra raleway-regular'></Form.Control> : <Select name='provincia_origen' value={valuesSelect?.primer} onChange={cambiarValue} isDisabled={formCoti?.origen?.direccion?.length < 6} options={[{ nombre: 'Seleccione', idprovincia: 0 }, ...combosProvincias].map((option) => ({ value: `${option.nombre}?${option.idprovincia}?direccion_origen`, label: option.nombre, select: 'primer' }))} />}
                      </FormGroup>
                      <FormGroup className='col-12 pt-0 m-0'>
                        <Form.Label className='gilroy-bold'>Localidad</Form.Label>
                        {tipo === 'ct' ? <Form.Control disabled={formDisabled} onChange={cambiarValue} name='ciudad-origen' value={formCoti.origen.ciudad} className='colorLetra raleway-regular'></Form.Control> : <Select name='ciudad_origen' value={valuesSelect?.segundo} onChange={cambiarValue} isDisabled={disabledLocalidad?.direccion_origen} options={[{ nombre: 'Seleccione', idlocalidad: 0 }, ...combosLocalidadesOrigen].map((option) => ({ value: `${option.nombre}?${option.idlocalidad}?direccion_origen`, label: option.nombre, select: 'segundo' }))} />}
                      </FormGroup>
                      {cpManual?.origen && (
                        <FormGroup className='col-12 pt-0 m-0 border-bottom'>
                          <Form.Label className='gilroy-bold'>Codigo postal</Form.Label>
                          <Form.Control onChange={cambiarValue} name='codigoPostal-origen' value={formCoti.origen.codigoPostal} className='colorLetra raleway-regular'></Form.Control>
                          {!String(formCoti.origen.codigoPostal)?.length && (
                            <div className='alert alert-danger p-2 mt-2' role='alert'>
                              Ingrese manualmente el codigo postal
                            </div>
                          )}
                        </FormGroup>
                      )}
                      {devolverOrigenTienda(formCoti.idproveedor, formCoti)}
                      <FormGroup className='col-12 pt-0 m-0'>
                        <Form.Label className='gilroy-bold'>Numero de telefono</Form.Label>
                        <Form.Control className='colorLetra raleway-regular' disabled={formDisabled} onChange={cambiarValue} name='telefono-origen' value={formCoti.origen.telefono}></Form.Control>
                      </FormGroup>
                      <FormGroup className='col-12 pt-0 m-0'>
                        <Form.Label className='gilroy-bold'>Observaciones</Form.Label>
                        <Form.Control className='colorLetra raleway-regular h-textarea' as='textarea' disabled={formDisabled} onChange={cambiarValue} name='observaciones-origen' value={formCoti.origen.observaciones}></Form.Control>
                      </FormGroup>
                    </div>
                    <div className='col-12 col-lg-4 row mt-2 border rounded'>
                      <Form.Label className='fs-5 gilroy-bold'>Destino</Form.Label>
                      <FormGroup className='col-12 pt-0 m-0'>
                        <Form.Label className='gilroy-bold'>Dirección</Form.Label>
                        <Form.Control disabled={formDisabled} onChange={cambiarValue} name='direccion-destino' value={formCoti.destino.direccion} className='colorLetra raleway-regular'></Form.Control>
                      </FormGroup>
                      <FormGroup className='col-12 pt-0 m-0'>
                        <Form.Label className='gilroy-bold'>Provincia</Form.Label>
                        {tipo === 'ct' ? <Form.Control disabled={formDisabled} onChange={cambiarValue} name='provincia-destino' value={formCoti.destino.provincia} className='colorLetra raleway-regular'></Form.Control> : <Select name='provincia_destino' value={valuesSelect?.tercero} onChange={cambiarValue} isDisabled={formCoti?.destino?.direccion?.length < 6} options={[{ nombre: 'Seleccione', idprovincia: 0 }, ...combosProvincias].map((option) => ({ value: `${option.nombre}?${option.idprovincia}?direccion_destino`, label: option.nombre, select: 'tercero' }))} />}
                      </FormGroup>
                      <FormGroup className='col-12 pt-0 m-0'>
                        <Form.Label className='gilroy-bold'>Localidad</Form.Label>
                        {tipo === 'ct' ? <Form.Control disabled={formDisabled} onChange={cambiarValue} name='ciudad-destino' value={formCoti.destino.ciudad} className='colorLetra raleway-regular'></Form.Control> : <Select name='ciudad_destino' value={valuesSelect?.cuarto} onChange={cambiarValue} isDisabled={disabledLocalidad?.direccion_destino} options={[{ nombre: 'Seleccione', idlocalidad: 0 }, ...combosLocalidadesDestino].map((option) => ({ value: `${option.nombre}?${option.idlocalidad}?direccion_destino`, label: option.nombre, select: 'cuarto' }))} />}
                      </FormGroup>
                      {cpManual?.destino && (
                        <FormGroup className='col-12 pt-0 m-0'>
                          <Form.Label className='gilroy-bold'>Codigo postal</Form.Label>
                          <Form.Control onChange={cambiarValue} name='codigoPostal-destino' value={formCoti.destino.codigoPostal} className='colorLetra raleway-regular'></Form.Control>
                          {!String(formCoti.destino.codigoPostal).length && (
                            <div className='alert alert-danger p-2 mt-2' role='alert'>
                              Ingrese manualmente el codigo postal
                            </div>
                          )}
                        </FormGroup>
                      )}
                      {devolverDestinoTienda(formCoti.idproveedor, formCoti)}
                      <FormGroup className='col-12 pt-0 m-0'>
                        <Form.Label className='gilroy-bold'>Numero de telefono</Form.Label>
                        <Form.Control disabled={formDisabled} onChange={cambiarValue} name='telefono-destino' value={formCoti.destino.telefono} className='colorLetra raleway-regular'></Form.Control>
                      </FormGroup>
                      <FormGroup className='col-12 pt-0 m-0'>
                        <Form.Label className='gilroy-bold'>Observaciones</Form.Label>
                        <Form.Control className='colorLetra raleway-regular h-textarea' as='textarea' disabled={formDisabled} onChange={cambiarValue} name='observaciones-destino' value={formCoti.destino.observaciones}></Form.Control>
                      </FormGroup>
                    </div>
                    <div className='col-12 col-lg-4 row mt-2 border rounded'>
                      <label className='fs-5 gilroy-bold text-uppercase mb-2 mt-0'>Agregar productos</label>
                      <Form.Group className='col-12 pt-0 mt-0'>
                        <FormGroup className='col-12 pt-0 mt-0'>
                          <Form.Label className='gilroy-bold'>Nombre</Form.Label>
                          <Form.Control disabled={formDisabled} onChange={cambiarProducto} name='articulo' value={producto?.articulo} className='colorLetra raleway-regular'></Form.Control>
                        </FormGroup>
                        <FormGroup className='col-12'>
                          <Form.Label className='gilroy-bold'>Cantidad</Form.Label>
                          <Form.Control disabled={formDisabled} onChange={cambiarProducto} min={1} name='cantidad' type='number' value={producto?.cantidad} className='colorLetra raleway-regular'></Form.Control>
                        </FormGroup>
                        <FormGroup className='col-12'>
                          <Form.Label className='gilroy-bold'>Peso (KG)</Form.Label>
                          <Form.Control disabled={formDisabled} onChange={cambiarProducto} name='peso' type='number' min={0} value={producto?.peso} className='colorLetra raleway-regular'></Form.Control>
                        </FormGroup>
                        <FormGroup className='col-12'>
                          <Form.Label className='gilroy-bold'>Alto (CM)</Form.Label>
                          <Form.Control disabled={formDisabled} onChange={cambiarProducto} name='alto' type='number' min={1} value={producto?.alto} className='colorLetra raleway-regular'></Form.Control>
                        </FormGroup>
                        <FormGroup className='col-12'>
                          <Form.Label className='gilroy-bold'>Ancho (CM)</Form.Label>
                          <Form.Control disabled={formDisabled} onChange={cambiarProducto} name='ancho' type='number' min={1} value={producto?.ancho} className='colorLetra raleway-regular'></Form.Control>
                        </FormGroup>
                        <FormGroup className='col-12'>
                          <Form.Label className='gilroy-bold'>Largo (CM)</Form.Label>
                          <Form.Control disabled={formDisabled} onChange={cambiarProducto} name='largo' type='number' min={1} value={producto?.largo} className='colorLetra raleway-regular'></Form.Control>
                        </FormGroup>
                        <FormGroup className='col-12'>
                          <Form.Label className='gilroy-bold'>Valor</Form.Label>
                          <Form.Control disabled={formDisabled} onChange={cambiarProducto} name='valor' type='number' min={1} value={producto?.valor} className='colorLetra raleway-regular'></Form.Control>
                        </FormGroup>
                      </Form.Group>
                      <div className='col-12'>
                        <button className='btn btn-filtro-flexit col-12 ms-auto' disabled={formDisabled} onClick={agregarProducto}>
                          Agregar
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className='col-12 text-center border-top'>
                    <Form.Label className='fs-5 gilroy-bold pt-2'>Productos</Form.Label>
                    <Tabla setFormCotizacion={setFormCotizacion} arrayHead={['Editar', 'Articulo', 'Cantidad', 'Alto', 'Ancho', 'Largo', 'Peso', 'Valor', 'Eliminar']} formCoti={formCoti} accessKey={key} formCotizacion={formCotizacion} formDisabled={formDisabled} array={masajeoProductos(formCoti.productos)} eliminarProducto={eliminarProducto} />
                  </div>
                </div>
              );
            })}
          </>
        ) : (
          <Tabs defaultActiveKey={0} id='uncontrolled-tab-example' className='mb-3' justify>
            {Array.isArray(formCotizacion) &&
              formCotizacion.map((formCoti, key) => {
                return (
                  <Tab key={key} eventKey={key} title={formCoti.nro_venta || ''} className='background-tab'>
                    <div className='row mt-2 border-top'>
                      <div className='row m-0 p-0 justify-content-between'>
                        <div className='col-12 col-lg-4 row mt-2 border rounded'>
                          <Form.Label className='fs-5 gilroy-bold'>Origen</Form.Label>
                          <FormGroup className='col-12 pt-0 m-0'>
                            <Form.Label className='gilroy-bold'>Dirección</Form.Label>
                            <Form.Control disabled={formDisabled} onChange={cambiarValue} name='direccion-origen' value={formCoti.origen.direccion} className='colorLetra raleway-regular'></Form.Control>
                          </FormGroup>
                          <FormGroup className='col-12 pt-0 m-0'>
                            <Form.Label className='gilroy-bold'>Provincia</Form.Label>
                            {tipo === 'ct' ? <Form.Control disabled={formDisabled} onChange={cambiarValue} name='provincia-origen' value={formCoti.origen.provincia} className='colorLetra raleway-regular'></Form.Control> : <Select value={valuesSelect?.primer} onChange={cambiarValue} isDisabled={formCoti?.origen?.direccion?.length < 6} options={[{ nombre: 'Seleccione', idprovincia: 0 }, ...combosProvincias].map((option) => ({ value: `${option.nombre}?${option.idprovincia}?direccion_origen`, label: option.nombre, select: 'primer' }))} />}
                          </FormGroup>
                          <FormGroup className='col-12 pt-0 m-0'>
                            <Form.Label className='gilroy-bold'>Localidad</Form.Label>
                            {tipo === 'ct' ? <Form.Control disabled={formDisabled} onChange={cambiarValue} name='ciudad-origen' value={formCoti.origen.ciudad} className='colorLetra raleway-regular'></Form.Control> : <Select value={valuesSelect?.segundo} onChange={cambiarValue} isDisabled={disabledLocalidad?.direccion_origen} options={[{ nombre: 'Seleccione', idlocalidad: 0 }, ...combosLocalidadesOrigen].map((option) => ({ value: `${option.nombre}?${option.idlocalidad}?direccion_origen`, label: option.nombre, select: 'segundo' }))} />}
                          </FormGroup>
                          {cpManual?.origen && (
                            <FormGroup className='col-12 pt-0 m-0 border-bottom'>
                              <Form.Label className='gilroy-bold'>Codigo postal</Form.Label>
                              <Form.Control onChange={cambiarValue} name='codigoPostal-origen' value={formCoti.origen.codigoPostal} className='colorLetra raleway-regular'></Form.Control>
                              {!String(formCoti.origen.codigoPostal)?.length && (
                                <div className='alert alert-danger p-2 mt-2' role='alert'>
                                  Ingrese manualmente el codigo postal
                                </div>
                              )}
                            </FormGroup>
                          )}
                          {devolverOrigenTienda(formCoti.idproveedor, formCoti)}
                          <FormGroup className='col-12 pt-0 m-0'>
                            <Form.Label className='gilroy-bold'>Numero de telefono</Form.Label>
                            <Form.Control className='colorLetra raleway-regular' disabled={formDisabled} onChange={cambiarValue} name='telefono-origen' value={formCoti.origen.telefono}></Form.Control>
                          </FormGroup>
                          <FormGroup className='col-12 pt-0 m-0'>
                            <Form.Label className='gilroy-bold'>Observaciones</Form.Label>
                            <Form.Control className='colorLetra raleway-regular h-textarea' as='textarea' disabled={formDisabled} onChange={cambiarValue} name='observaciones-origen' value={formCoti.origen.observaciones}></Form.Control>
                          </FormGroup>
                        </div>
                        <div className='col-12 col-lg-4 row mt-2 border rounded'>
                          <Form.Label className='fs-5 gilroy-bold'>Destino</Form.Label>
                          <FormGroup className='col-12 pt-0 m-0'>
                            <Form.Label className='gilroy-bold'>Dirección</Form.Label>
                            <Form.Control disabled={formDisabled} onChange={cambiarValue} name='direccion-destino' value={formCoti.destino.direccion} className='colorLetra raleway-regular'></Form.Control>
                          </FormGroup>
                          <FormGroup className='col-12 pt-0 m-0'>
                            <Form.Label className='gilroy-bold'>Provincia</Form.Label>
                            {tipo === 'ct' ? <Form.Control disabled={formDisabled} onChange={cambiarValue} name='provincia-destino' value={formCoti.destino.provincia} className='colorLetra raleway-regular'></Form.Control> : <Select value={valuesSelect?.tercero} onChange={cambiarValue} isDisabled={formCoti?.destino?.direccion?.length < 6} options={[{ nombre: 'Seleccione', idprovincia: 0 }, ...combosProvincias].map((option) => ({ value: `${option.nombre}?${option.idprovincia}?direccion_destino`, label: option.nombre, select: 'tercero' }))} />}
                          </FormGroup>
                          <FormGroup className='col-12 pt-0 m-0'>
                            <Form.Label className='gilroy-bold'>Localidad</Form.Label>
                            {tipo === 'ct' ? <Form.Control disabled={formDisabled} onChange={cambiarValue} name='ciudad-destino' value={formCoti.destino.ciudad} className='colorLetra raleway-regular'></Form.Control> : <Select value={valuesSelect?.cuarto} onChange={cambiarValue} isDisabled={disabledLocalidad?.direccion_destino} options={[{ nombre: 'Seleccione', idlocalidad: 0 }, ...combosLocalidadesDestino].map((option) => ({ value: `${option.nombre}?${option.idlocalidad}?direccion_destino`, label: option.nombre, select: 'cuarto' }))} />}
                          </FormGroup>
                          {cpManual?.destino && (
                            <FormGroup className='col-12 pt-0 m-0'>
                              <Form.Label className='gilroy-bold'>Codigo postal</Form.Label>
                              <Form.Control onChange={cambiarValue} name='codigoPostal-destino' value={formCoti.destino.codigoPostal} className='colorLetra raleway-regular'></Form.Control>
                              {!String(formCoti.destino.codigoPostal).length && (
                                <div className='alert alert-danger p-2 mt-2' role='alert'>
                                  Ingrese manualmente el codigo postal
                                </div>
                              )}
                            </FormGroup>
                          )}
                          {devolverDestinoTienda(formCoti.idproveedor, formCoti)}
                          <FormGroup className='col-12 pt-0 m-0'>
                            <Form.Label className='gilroy-bold'>Numero de telefono</Form.Label>
                            <Form.Control disabled={formDisabled} onChange={cambiarValue} name='telefono-destino' value={formCoti.destino.telefono} className='colorLetra raleway-regular'></Form.Control>
                          </FormGroup>
                          <FormGroup className='col-12 pt-0 m-0'>
                            <Form.Label className='gilroy-bold'>Observaciones</Form.Label>
                            <Form.Control className='colorLetra raleway-regular h-textarea' as='textarea' disabled={formDisabled} onChange={cambiarValue} name='observaciones-destino' value={formCoti.destino.observaciones}></Form.Control>
                          </FormGroup>
                        </div>
                        <div className='col-12 col-lg-4 row mt-2 pt-0 border rounded'>
                          <Form.Label className='fs-5 gilroy-bold pt-0 mt-0'>Agregar productos</Form.Label>
                          <Form.Group className='col-12'>
                            <FormGroup className='col-12'>
                              <Form.Label className='gilroy-bold'>Nombre</Form.Label>
                              <Form.Control disabled={formDisabled} onChange={cambiarProducto} name='articulo' value={producto?.articulo} className='colorLetra raleway-regular'></Form.Control>
                            </FormGroup>
                            <FormGroup className='col-12'>
                              <Form.Label className='gilroy-bold'>Cantidad</Form.Label>
                              <Form.Control disabled={formDisabled} onChange={cambiarProducto} min={1} name='cantidad' type='number' value={producto?.cantidad} className='colorLetra raleway-regular'></Form.Control>
                            </FormGroup>
                            <FormGroup className='col-12'>
                              <Form.Label className='gilroy-bold'>Peso (KG)</Form.Label>
                              <Form.Control disabled={formDisabled} onChange={cambiarProducto} name='peso' type='number' min={0} value={producto?.peso} className='colorLetra raleway-regular'></Form.Control>
                            </FormGroup>
                            <FormGroup className='col-12'>
                              <Form.Label className='gilroy-bold'>Alto (CM)</Form.Label>
                              <Form.Control disabled={formDisabled} onChange={cambiarProducto} name='alto' type='number' min={1} value={producto?.alto} className='colorLetra raleway-regular'></Form.Control>
                            </FormGroup>
                            <FormGroup className='col-12'>
                              <Form.Label className='gilroy-bold'>Ancho (CM)</Form.Label>
                              <Form.Control disabled={formDisabled} onChange={cambiarProducto} name='ancho' type='number' min={1} value={producto?.ancho} className='colorLetra raleway-regular'></Form.Control>
                            </FormGroup>
                            <FormGroup className='col-12'>
                              <Form.Label className='gilroy-bold'>Largo (CM)</Form.Label>
                              <Form.Control disabled={formDisabled} onChange={cambiarProducto} name='largo' type='number' min={1} value={producto?.largo} className='colorLetra raleway-regular'></Form.Control>
                            </FormGroup>
                            <FormGroup className='col-12'>
                              <Form.Label className='gilroy-bold'>Valor</Form.Label>
                              <Form.Control disabled={formDisabled} onChange={cambiarProducto} name='valor' type='number' min={1} value={producto?.valor} className='colorLetra raleway-regular'></Form.Control>
                            </FormGroup>
                          </Form.Group>
                          <div className='col-12'>
                            <button className='btn btn-filtro-flexit col-12 ms-auto' disabled={formDisabled} onClick={agregarProducto}>
                              Agregar
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className='col-12 text-center border-top'>
                        <Form.Label className='fs-5 gilroy-bold pt-2'>Productos</Form.Label>
                        <Tabla setFormCotizacion={setFormCotizacion} arrayHead={['Editar', 'Articulo', 'Cantidad', 'Alto', 'Ancho', 'Largo', 'Peso', 'Valor', 'Eliminar']} formCoti={formCoti} accessKey={key} formCotizacion={formCotizacion} formDisabled={formDisabled} array={masajeoProductos(formCoti.productos)} eliminarProducto={eliminarProducto} />
                      </div>
                    </div>
                  </Tab>
                );
              })}
          </Tabs>
        )}
      </div>
      <div id='step-3' className={`tab-pane ${loading ? 'd-flex justify-content-center' : ''}`} role='tabpanel' aria-labelledby='step-3' style={{ display: 'block' }}>
        {loading ? (
          <div className='spinner-border spinner-flexit spinner' style={{ height: '100px', width: '100px' }} role='status' hidden={!loading}>
            <span className='visually-hidden'>Loading...</span>
          </div>
        ) : mensajeStep3?.length ? (
          <div>
            <h3 className='gilroy-medium mt-2 mb-2'>{mensajeStep3}</h3>
          </div>
        ) : (
          <div>
            <h2 className='gilroy-regular valor-cotizacion'>Valor de la cotización.</h2>
            <div>
              <table className='table table-bordered table-striped table-responsive w-auto'>
                <thead>{devolverTrHead(Object.values(valorCotizacion || []))}</thead>
                <tbody>
                  {Object.values(valorCotizacion || [])?.map((value, key) => {
                    return (
                      Array.isArray(value) &&
                      value?.map((val, k) => {
                        return key === 0 && devolverTr(val, k, Object.values(valorCotizacion || []));
                      })
                    );
                  })}
                </tbody>
              </table>
            </div>
            {/* {devolverValorCotizacion(valorCotizacion)} */}
          </div>
        )}
      </div>
      <div id='step-4' className={`tab-pane ${loading ? 'd-flex justify-content-center' : ''}`} role='tabpanel' aria-labelledby='step-4' style={{ display: 'block' }}>
        <h2 hidden={loading} className='gilroy-medium text-envios'>
          Envios
        </h2>
        {devolverTablaEnvios(datosCotizacionFinal)}
      </div>
    </>
  );
};
export default EurekaStepCotizacion;
