import React from 'react';
import Grid from '../estructura/EurekaGrid/EurekaGrid';

interface IStockArticulos {
  recargaGridStock: string;
  setRecargaGridStock: Function;
  offcanvasArticulos: any;
  manejoAcciones?: any;
}

const StockArticulos: React.FC<IStockArticulos> = ({ manejoAcciones,recargaGridStock, setRecargaGridStock, offcanvasArticulos }) => {
  return <>{Grid(manejoAcciones, 'stock', recargaGridStock, setRecargaGridStock, offcanvasArticulos)}</>;
};
export default StockArticulos;
