import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGear, faPen } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment-timezone';
import React, { useState } from 'react';
import { Badge } from 'react-bootstrap';
import EurekaModals from '../../EurekaModals/EurekaModals';
import EurekaGeneral from '../../EurekaGeneral/EurekaGeneral';
import { tituloModalTableroOrdenes, FormCanceladosOrdenes } from './Genericos/FormGenericos';
import FormFormularioOrdenes from '../../../EurekaDashboard/TabOrdenes/Forms/FormFormularioOrdenes';
import EurekaOverlayTrigger from '../../EurekaOverlayTrigger/EurekaOverlayTrigger';
import $ from 'jquery';

interface IEurekaOrdenesPendientes {
  pendientes: {
    idcliente: number;
    nroremito: number;
    idremito: number;
    fecha_alta: string;
    mod_manual: boolean;
    tipoconector: string;
    productos: string;
    observaciones: string;
  }[];
  direccion: boolean;
  SetRefresh: Function;
}

const EurekaOrdenesPendientes: React.FC<IEurekaOrdenesPendientes> = ({ pendientes = [], direccion = true, SetRefresh }) => {
  const [chequeosPendientes, setChequeosPendientes] = useState([]);
  const [modalPendiente, setmodalPendiente] = useState({ show: false, tipo: 0, accion: 'c', id: 0 });
  const [formDisabled, setformDisabled] = useState(false);
  const ModalClose = () => setmodalPendiente({ show: false, tipo: 0, accion: 'c', id: 0 });
  const ModalShow = (tipo: number, accion: string) => setmodalPendiente({ show: true, tipo: tipo, accion: accion, id: 0 });
  function checkallpendientes() {
    if ($('.all_chk_pendientes').is(':checked')) {
      document.querySelectorAll<HTMLInputElement>('[class^="chk_p_ord"]').forEach((input) => {
        input.checked = true;
      });
    } else {
      document.querySelectorAll<HTMLInputElement>('[class^="chk_p_ord"]').forEach((input) => {
        input.checked = false;
      });
    }
  }
  function devolverName(name: string | null) {
    if (name) return '.' + name;
    else return '[class^="chk_p_ord"]:checked';
  }
  function getallcheckspendientes(name: string | null) {
    var bolsa: any = [];
    document.querySelectorAll<HTMLInputElement>(devolverName(name)).forEach((input) => {
      const idremito = Number(input.value);
      const nroremito = input.dataset.nroremito;
      const idcliente = Number(input.dataset.idcliente);
      const bolsa_ = { idremito: idremito, nroremito: nroremito, idcliente: idcliente };
      bolsa.push(bolsa_);
    });
    setChequeosPendientes(bolsa);
  }
  function btn_cancelar_orden(idremito: number | null, name: string | null) {
    if (idremito && name) {
      getallcheckspendientes(name);
      ModalShow(1, 'b');
    } else {
      getallcheckspendientes(null);
      ModalShow(1, 'b');
    }
  }
  function btn_modificar_orden(idremito: number, name: string) {
    getallcheckspendientes(name);
    setformDisabled(false);
    ModalShow(2, 'm');
  }
  function btn_consultar_orden(idremito: number, name: string) {
    getallcheckspendientes(name);
    setformDisabled(true);
    ModalShow(2, 'c');
  }
  return (
    <>
      <EurekaModals manejador={modalPendiente} modalTitulo={tituloModalTableroOrdenes(modalPendiente.accion)} sizeModal={EurekaGeneral.tamanio_modal_tablero(chequeosPendientes, modalPendiente.tipo)} handleClose={ModalClose}>
        {modalPendiente.tipo === 1 ? (
          <FormCanceladosOrdenes ListaChecks={chequeosPendientes} SetRefresh={SetRefresh} ModalClose={ModalClose} />
        ) : modalPendiente.tipo === 2 ? (
          <FormFormularioOrdenes ListaChecks={chequeosPendientes} SetRefresh={SetRefresh} estadoOrdenes={modalPendiente} formDisabled={formDisabled} handleClose={ModalClose} />
        ) : (
          <div className='alert alert-danger' role='alert'>
            No hay remitos seleccionadas
          </div>
        )}
      </EurekaModals>
      <div className={direccion ? 'col-12 col-lg-4' : 'col-md-12'}>
        <div className='card col_entregas_pendientes'>
          <div className='card-header eureka-bg-primary'>
            <div className='row'>
              <div className='col-10 text-light'>
                <input className='all_chk_pendientes' onClick={checkallpendientes} type='checkbox' /> Remitos Pendientes <Badge bg='primary'>{pendientes.length}</Badge>
              </div>
              <div className='col-2 text-end'>
                <div className='dropdow'>
                  <button className='btn btn-primary btn-sm dropdown-toggle' type='button' data-bs-toggle='dropdown' aria-expanded='false'>
                    +
                  </button>
                  <ul className='dropdown-menu'>
                    <li>
                      <button className='btn btn-xs dropdown-item' onClick={() => btn_cancelar_orden(null, null)}>
                        Eliminar remitos
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className='card-body'>
            <div className={`accordion ${direccion ? '' : 'row'}`} id='accordionPendientes'>
              {pendientes.map((pendiente, key) => {
                return (
                  <div className={`accordion-item ${direccion ? 'col-md-12' : 'col-md-4'}`} key={key}>
                    <div className='accordion-header eureka-bg-primary accordion-pendiente'>
                      <nav className='navbar bg-body-tertiary'>
                        <div className='container-fluid'>
                          <div className='form-check'>
                            <input className={'chk_p_ord' + key + ' form-check-input me-1'} data-idcliente={pendiente.idcliente} data-nroremito={pendiente.nroremito} type='checkbox' value={pendiente.idremito} id={'chk_pen_' + key} />
                            <label className='text-ligth form-check-label' data-bs-toggle='collapse' data-bs-target={'#collapse_pen_' + key} aria-expanded='false' aria-controls={'collapse_pen_' + key} /* onMouseEnter={underline} onMouseLeave={noUnderline} */>
                              #{pendiente.nroremito} - {moment(pendiente.fecha_alta).format('DD/MM HH:mm')}
                            </label>
                            {pendiente.mod_manual && (
                              <EurekaOverlayTrigger
                                title={
                                  <Badge bg='secondary' className='ms-2' style={{ textDecoration: 'none' }}>
                                    <FontAwesomeIcon icon={faPen} />
                                  </Badge>
                                }
                                isButton={false}
                                variant=''>
                                <p>Modificado por {pendiente.mod_manual}</p>
                              </EurekaOverlayTrigger>
                            )}
                          </div>
                          <div className='dropdow'>
                            <button className='btn btn-primary btn-sm dropdown-toggle' type='button' data-bs-toggle='dropdown' aria-expanded='false'>
                              {pendiente.tipoconector || <FontAwesomeIcon icon={faGear} />}
                            </button>
                            <ul className='dropdown-menu dropdown-menu-end'>
                              <li>
                                <a onClick={(e) => btn_consultar_orden(pendiente.idremito, 'chk_p_ord' + key)} className='dropdown-item'>
                                  {' '}
                                  Consultar
                                </a>
                              </li>
                              <li>
                                <a onClick={(e) => btn_modificar_orden(pendiente.idremito, 'chk_p_ord' + key)} className='dropdown-item'>
                                  {' '}
                                  Modificar
                                </a>
                              </li>
                              <li className='w-100'>
                                <a onClick={(e) => btn_cancelar_orden(pendiente.idremito, 'chk_p_ord' + key)} className='dropdown-item'>
                                  {' '}
                                  Eliminar
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </nav>
                    </div>
                    <div id={'collapse_pen_' + key} className='accordion-collapse collapse' data-bs-parent='#accordionPendientes'>
                      <div className='text-dark accordion-body'>
                        <small>{JSON.parse(pendiente?.productos || '[]')?.map((value: { cantidad: string; producto: string, sku:string }) => <>{`(${value.cantidad}) ${value.sku} - ${value.producto}`}<br/></>)}</small>
                        <hr className='border border-dark'></hr>
                        <small>
                          Nro remito:{' '}
                          <a>
                            <small>{pendiente.nroremito}</small>
                          </a>
                        </small>
                        <br />
                        <small>Observaciones: {pendiente.observaciones}</small>
                        <br />
                      </div>
                    </div>
                  </div>
                );
              })}
              {/*                             <div className="d-flex justify-content-center align-items-center pt-2">
                                <button hidden={pendientes.length < 12} className="btn btn-sm btn-primary" onClick={() => setVermas({ ...vermas, pendientes: !vermas.pendientes })}>{pendientes.length <= 12 ? "Ver mas" : "Ver menos"}</button>
                            </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EurekaOrdenesPendientes;
