import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { EnviarMensaje, GetEureka, PostEureka } from '../../estructura/EurekaGeneral/EurekaGeneral';
import axios from 'axios';

interface IFormShopify {
  modalClose: React.MouseEventHandler<HTMLButtonElement>;
  id: number;
}

const FormWooWix: React.FC<IFormShopify> = ({ modalClose, id }) => {
  const { register, handleSubmit, getValues, setError, formState:{errors} } = useForm({
    defaultValues: {
      url_tienda: '',
      scope: 'read_write'
    },
  });
  const [popup, setPopup] = useState<Window | null>(null);
  const [consumerKey, setConsumerKey] = useState<string>('');
  const [consumerSecret, setConsumerSecret] = useState<string>('');
  const [user_id, setUserId] = useState<string>('');
  const [block, setBlock] = useState<boolean>(false);

  const openPopup = (url: string) => {
    const popupWindow: any = window.open(url, 'popup', 'width=600,height=600');
    setPopup(popupWindow);
  };

  const quitarBarraFinal = (url:string) => {
    return url.endsWith('/') ? url.slice(0, -1) : url;
  };

  useEffect(() => {
    const interval = setInterval(() => {

      if (popup && !popup.closed) {
        try {
          if (popup.location.href.includes('success=1')) {
            popup.close();
            GetEureka(`/Conector/api/get_keys`)
            .then((response) => {
              if(response.content.consumer_key == null || response.content.consumer_secret == null){
                EnviarMensaje('danger', 'Tuvimos un error al registrar el conector, por favor intente nuevamente.');
                return;
              }
              setConsumerKey(response.content.consumer_key);
              setConsumerSecret(response.content.consumer_secret);
              setUserId(response.content.code);
              setBlock(true);
            })
            
            clearInterval(interval);
          }
          else{
            EnviarMensaje('danger', 'WooCommerce no pudo encontrar la tienda asociada, intente de nuevo.');
            popup.close();
          }
        } catch (error) {
        }
      } else {
        clearInterval(interval);
      }
    }, 4000);
    return () => {
      clearInterval(interval);
    };
  }, [popup]);

  const onSubmit = (e: any) => {
    const urlFormateada = quitarBarraFinal(e.url_tienda);
    GetEureka(`/Conector/api/wc_keys`, `&url_tienda=${e.url_tienda}&scope=${e.scope}`)
      .then((response) => {
        if (response.info) {
          const PopUpurl = response.content;
          openPopup(PopUpurl);
        }
      })
  };
  const altaConector = () => {
    const config = {
      woo_url: getValues('url_tienda'),
      woo_clave: consumerKey,
      woo_clave_secreta: consumerSecret,
    }
    PostEureka(`/Conector/api/alta_conector`, { config: config, conector: 'wc', tipo: 5 })
    .then((response) => {
      if (!response.info) {
        EnviarMensaje('danger', response.content);
        modalClose(response);
      } else {
        EnviarMensaje('success', 'Registro ingresado con éxito');
        modalClose(response);
      }
    });
  }
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {/* <div hidden={id === 5}>
        <a href='https://cgg.unr.mybluehost.me/testing/assets/downloads/flexit-woocommerce.zip' rel='noopener noreferrer' target='_blank' className='btn btn-default btn-block mt-3 border w-100'>
          Descargar plugin
        </a>
        <hr className='border-dark' />
      </div> */}
      <div className='row'>
        <div className='col-12'>
          <label>URL de la tienda</label>
          <input type='text' className='form-control' {...register('url_tienda', {required:'Obligatorio'})} disabled={block} />
          {errors.url_tienda && <span className='text-danger'>{errors.url_tienda.message}</span>}
        </div>
        <div className='col-12 mb-3 '>
          <label>Permisos</label>
          <select className='form-select' {...register('scope')} disabled={block}>
            <option value='read'>Read</option>
            <option value='write'>Write</option>
            <option value='read_write'>Read and Write</option>
          </select>
        </div>
      </div>
      {
        consumerKey && consumerSecret ? (
          <>
            <div className='alert alert-success mt-3'>
              <h5>Claves generadas</h5>
              <p>Consumer Key: {consumerKey}</p>
              <p>Consumer Secret: {consumerSecret}</p>
              <div className='alert alert-info mt-3'>
                <p>
                  Podras ver estas claves en el apartado de "Conectores".
                </p>
              </div>
            </div>
          </>
        ) : null
      }
      <div className='d-flex justify-content-center'>
        {
          block ? (
            <button type='button' className='btn btn-primary ms-3' onClick={altaConector}>
              Guardar Conector
            </button>
          ) :
            <button type='submit' className='btn btn-primary ms-3'>
              Autenticarse en WooCommerce
            </button>
        }
      </div>
    </form>
  );
};

export default FormWooWix;