import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { FlexitProvider } from './context/Flexit';

const rootElement = document.getElementById('root');
if (!rootElement) throw new Error('El elemento con id "root" no se encuentra en el DOM');

const root = ReactDOM.createRoot(rootElement);

root.render(

    <FlexitProvider>
      <App />
    </FlexitProvider>

);
