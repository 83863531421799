import React, { useState } from 'react';
import { Card } from 'react-bootstrap';
import PageLayout from '../../layouts/PageLayout.tsx';
import Grid from '../estructura/EurekaGrid/EurekaGrid.tsx';
import EurekaModals from '../estructura/EurekaModals/EurekaModals.tsx';
import FormArticulos from './Form-Articulos.tsx';
import EurekaOffcanvas from '../estructura/EurekaOffCanvas/EurekaOffCanvas.tsx';
import FiltrosArticulos from './Filtros-Articulos.tsx';
import FormImportarArticulo from './Form-Importar-Articulo.tsx';
import StockArticulos from './Stock-Articulos.tsx';
import moment from 'moment';
import { PostEureka, PutEureka, DeleteEureka } from '../estructura/EurekaGeneral/EurekaGeneral.ts';
import FiltrosInventario from './FiltrosInventario.tsx';

interface IBody {
  idarticulo: number;
  articulo: string;
  sku: string;
  stock_actual: number;
  stock_reservado: number;
  stock_disponible: number;
  stock_minimo: number;
  tipo: string;
  marca: string;
  modelo: string;
  proveedor: string;
  precio_venta: string;
  disabled: boolean;
  json_imagenes: string;
  idtipo: number;
  idmarca: number;
  idmodelo: number;
  idproveedor: number;
  alto: string;
  ancho: string;
  largo: string;
  valorizacion: string;
  peso: string;
  peso_volumetrico: string;
  idfiltroproducto: number;
  sku_flexit: string;
}

function EurekaArticulos() {
  const [modalArticulos, setmodalArticulos] = useState({
    show: false,
    id: 0,
    accion: 'a',
  });
  const ModalClose = () => setmodalArticulos({ show: false, id: 0, accion: 'a' });
  const ModalShow = (id: number, accion: string) => setmodalArticulos({ show: true, id: id, accion: accion });

  const [modalImportarAticulo, setmodalImportarAticulo] = useState({
    show: false,
    id: 0,
    accion: 'im',
  });
  const ModalCloseImportar = () => setmodalImportarAticulo({ show: false, id: 0, accion: 'im' });
  const ModalShowImportar = (id: number, accion: string) => setmodalImportarAticulo({ show: true, id: id, accion: accion });

  const [modalCreate, setmodalCreate] = useState({
    show: false,
    id: 0,
    accion: 'cr',
  });
  const ModalCloseCreate = () => setmodalCreate({ show: false, id: 0, accion: 'cr' });
  const ModalShowCreate = (id: number, accion: string) => setmodalCreate({ show: true, id: id, accion: accion });

  const [modalDelete, setmodalDelete] = useState({
    show: false,
    id: 0,
    accion: 'b',
  });
  const ModalCloseDelete = () => setmodalDelete({ show: false, id: 0, accion: 'b' });
  const ModalShowDelete = (id: number, accion: string) => setmodalDelete({ show: true, id: id, accion: accion });

  const [modalArticulosModificar, setmodalArticulosModificar] = useState({
    show: false,
    id: 0,
    accion: 'm',
  });
  const ModalCloseModificar = () => setmodalArticulosModificar({ show: false, id: 0, accion: 'm' });
  const ModalShowModificar = (id: number, accion: string) => setmodalArticulosModificar({ show: true, id: id, accion: accion });

  const [offcanvasArticulos, setoffcanvasArticulos] = useState({
    show: false,
    id: 0,
    accion: 'a',
  });
  const offCanvasClose = () => setoffcanvasArticulos({ show: false, id: 0, accion: 'a' });
  const offCanvasShow = (id: number, accion: string) => setoffcanvasArticulos({ show: true, id: id, accion: accion });
  const [recargaGridStock, setRecargaGridStock] = useState<string>('');

  const [offcanvasFiltrosArticulos, setoffcanvasFiltrosArticulos] = useState({
    show: false,
    id: 0,
    accion: 'a',
  });
  const offCanvasFiltrosClose = () => setoffcanvasFiltrosArticulos({ show: false, id: 0, accion: 'a' });
  const offCanvasFiltrosShow = (id: number, accion: string) => setoffcanvasFiltrosArticulos({ show: true, id: id, accion: accion });

  const [offcanvasFiltroInventario, setoffcanvasFiltroInventario] = useState({
    show: false,
    id: 0,
    accion: 'fi',
  });
  const offCanvasFiltroInventarioClose = () => setoffcanvasFiltroInventario({ show: false, id: 0, accion: 'fi' });
  const offCanvasFiltroInventarioShow = (id: number, accion: string) => setoffcanvasFiltroInventario({ show: true, id: id, accion: accion });

  const [recargaGridArticulos, setRecargaGridArticulos] = useState<string>('');
  const [formDisabled, setformDisabled] = useState(false);

  function HandleModification(body: any) {
    try {
      PutEureka('/Articulos/ModificarArticulo', body)
        .then(() => setRecargaGridArticulos(moment().format('YYYY-MM-DD HH:mm:ss')))
        .then(() => ModalCloseModificar());
    } catch (error) {
      console.log(error);
    }
  }

  const HandleCreate = (body: IBody) => {
    try {
      PostEureka('/Articulos/CrearArticulo', body)
        .then(() => setRecargaGridArticulos(moment().format('YYYY-MM-DD HH:mm:ss')))
        .then(() => ModalCloseCreate());
    } catch (error) {
      console.log(error);
    }
  };

  async function HandleDelete(body: IBody): Promise<void> {
    try {
      DeleteEureka(`/Articulos/EliminarArticulo/${body.idarticulo}`, {})
        .then(() => setRecargaGridArticulos(moment().format('YYYY-MM-DD HH:mm:ss')))
        .then(() => ModalCloseDelete());
    } catch (error) {
      console.log(error);
      throw new Error('Error a intentar crear el articulo');
    }
  }

  const manejo_acciones = (origen: string, registro: number, accion: string): void => {
    switch (accion) {
      case 'a':
        setformDisabled(false);
        ModalShow(registro, accion);
        break;
      case 'c':
        setformDisabled(true);
        ModalShow(registro, accion);
        break;
      case 'i':
        setformDisabled(false);
        offCanvasShow(registro, accion);
        break;
      case 'm':
        setformDisabled(false);
        ModalShowModificar(registro, accion);
        break;
      case 'cr':
        setformDisabled(false);
        ModalShowCreate(registro, accion);
        break;
      case 'im':
        setformDisabled(false);
        ModalShowImportar(registro, accion);
        break;
      case 'b':
        setformDisabled(true);
        ModalShowDelete(registro, accion);
        break;
      case 'f':
        setformDisabled(false);
        offCanvasFiltrosShow(registro, accion);
        break;
      case 'fi':
        setformDisabled(false);
        offCanvasFiltroInventarioShow(registro, accion);
        break;  
      default:
        return;
    }
  };

  return (
    <>
      <PageLayout label='Adm. articulos'>
        <Card>
          <Card.Body>
            <div className='d-flex'>
              <button
                className='btn btn-success btn-xs mb-4 me-1'
                onClick={() => {
                  manejo_acciones('sucursales', 0, 'cr');
                }}>
                {' '}
                <strong>+</strong> Agregar{' '}
              </button>
              <button
                className='btn btn-warning btn-xs mb-4 me-1'
                onClick={() => {
                  manejo_acciones('sucursales', 0, 'im');
                }}>
                {' '}
                <strong>+</strong> Importar{' '}
              </button>
            </div>
            {Grid(manejo_acciones, 'articulos', recargaGridArticulos, setRecargaGridArticulos)}
            <EurekaModals id='modal_articulos' manejador={modalArticulos} modalTitulo='Consultar articulo' sizeModal='xl' handleClose={ModalClose}>
              <FormArticulos estadoArticulos={modalArticulos} formDisabled={formDisabled} handleClose={ModalClose} disabledUpload={true}></FormArticulos>
            </EurekaModals>
            <EurekaModals id='modal_articulos_delete' manejador={modalDelete} modalTitulo='Eliminar articulo' sizeModal='xl' handleClose={ModalCloseDelete}>
              <FormArticulos estadoArticulos={modalDelete} formDisabled={formDisabled} handleClose={ModalCloseDelete} disabledUpload={true} handleDelete={HandleDelete}></FormArticulos>
            </EurekaModals>
            <EurekaModals id='modal_modificar_articulos' manejador={modalArticulosModificar} modalTitulo='Modificar articulo' sizeModal='xl' handleClose={ModalCloseModificar}>
              <FormArticulos estadoArticulos={modalArticulosModificar} formDisabled={formDisabled} handleClose={ModalCloseModificar} handleModification={HandleModification} SetRefresh={setRecargaGridArticulos}></FormArticulos>
            </EurekaModals>
            <EurekaModals id='modal_crear_articulos' manejador={modalCreate} modalTitulo='Crear articulo' sizeModal='xl' handleClose={ModalCloseCreate}>
              <FormArticulos estadoArticulos={modalCreate} formDisabled={formDisabled} handleClose={ModalCloseCreate} handleCreate={HandleCreate}></FormArticulos>
            </EurekaModals>
            <EurekaOffcanvas id='offcanvas_filtrosarticulos' manejador={offcanvasFiltrosArticulos} offcanvasTitulo='Filtros' handleClose={offCanvasFiltrosClose} posicion='start' size='w-20'>
              <FiltrosArticulos offCanvasFiltrosClose={offCanvasFiltrosClose} SetRefresh={setRecargaGridArticulos}></FiltrosArticulos>
            </EurekaOffcanvas>
            <EurekaOffcanvas id='offcanvas_filtroinventario' manejador={offcanvasFiltroInventario} offcanvasTitulo='Filtros' handleClose={offCanvasFiltroInventarioClose} posicion='start' size='w-20'>
              <FiltrosInventario offCanvasFiltrosClose={offCanvasFiltroInventarioClose} SetRefresh={setRecargaGridStock}></FiltrosInventario>
            </EurekaOffcanvas>
            <EurekaOffcanvas id='offcanvas_articulos' manejador={offcanvasArticulos} offcanvasTitulo='Inventario' handleClose={offCanvasClose} posicion='end' size='w-20'>
              <StockArticulos manejoAcciones={manejo_acciones} recargaGridStock={recargaGridStock} setRecargaGridStock={setRecargaGridStock} offcanvasArticulos={offcanvasArticulos}></StockArticulos>
            </EurekaOffcanvas>
            <EurekaModals id='modal_importar_articulos' manejador={modalImportarAticulo} modalTitulo='Importar articulo' sizeModal='xl' handleClose={ModalCloseImportar}>
              <FormImportarArticulo />
            </EurekaModals>
          </Card.Body>
        </Card>
      </PageLayout>
    </>
  );
}
export default EurekaArticulos;
